import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "./customerService";

export const getDiamongPassUser = createAsyncThunk(
    "customer/get-diamond-pass-user",
    async (params, thunkAPI) => {
        try {
            return await customerService.getUsers(params);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateDiamondPasssUserStatus = createAsyncThunk(
    "customer/update-user-status",
    async (params, thunkAPI) => {
        try {
            return await customerService.updateUsersStatus(params);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const initialState = {
    users: [],
    total: 0,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
    updatingStatus: false,
};

export const customerSlice = createSlice({
    name: "diamondPassusers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDiamongPassUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDiamongPassUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.users = action.payload.users;
                state.total = action.payload.userCount;
            })
            .addCase(getDiamongPassUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateDiamondPasssUserStatus.pending, (state, action) => {
                const { id, data } = action.meta.arg;                
                if (!data.is_diamond_pass_user) {
                    state.users = state.users.filter((user) => user.id !== id);
                }
                state.updatingStatus = true;
            })
            .addCase(updateDiamondPasssUserStatus.fulfilled, (state, action) => {
                state.updatingStatus = false;
            })
    },
});
export default customerSlice.reducer;
