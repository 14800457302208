import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationService from "./notificationService";

export const sendNotification = createAsyncThunk(
  "notification/send-notification",
  async (data, thunkAPI) => {
    try {
      return await notificationService.sendNotification(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUsers = createAsyncThunk(
  'notification/fetchUsers',
  async (data, thunkAPI) => {
    try {
      return await notificationService.getUsers(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  isError: false,
  isSuccess: false,
  message: "",
  sending: false,
  isSendSuccess: false,
  users: [],
  loading: false,
};
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    initSetting: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.sending = false;
      state.isSendSuccess = false;
      state.users = [];
      state.loading = false;
      state.error = null;
    },
    clearMessage(state) {
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendNotification.pending, (state, action) => {
        state.sending = true;
        state.isSendSuccess = false;
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.isError = true;
        state.sending = false;
        state.message = Array.isArray(action.payload.response.data.message)
          ? action.payload.response.data.message[0]
          : action.payload.response.data.message;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.sending = false;
        state.isSendSuccess = true;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default notificationSlice.reducer;
export const { initSetting, clearMessage } = notificationSlice.actions;