import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "./../features/cutomers/customerSlice";

const data1 = [];
for (let i = 0; i < 46; i++) {
  data1.push({
    key: i,
    name: `Edward King ${i}`,
    product: 32,
    staus: `London, Park Lane no. ${i}`,
  });
}
const Dashboard = () => {

  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getDashboardData());
  }, [dispatch]);

  return (
    <div>
      <h3 className="mb-4 title">Dashboard</h3>
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Current Users</p>
            <h4 className="mb-0 sub-title">{dashboardData.user_count}</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6>
              {/* <BsArrowDownRight /> 32% */}
            </h6>
            {/* <p className="mb-0  desc">Compared To April 2022</p> */}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total User History</p>
            <h4 className="mb-0 sub-title">{dashboardData.all_user_count}</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6>
              {/* <BsArrowDownRight /> 32% */}
            </h6>
            {/* <p className="mb-0  desc">Compared To April 2022</p> */}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total Match</p>
            <h4 className="mb-0 sub-title">{dashboardData.matched_count}</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6 className="red">
              {/* <BsArrowDownRight /> 32% */}
            </h6>
            {/* <p className="mb-0  desc">Compared To April 2022</p> */}
          </div>
        </div>
        {/* <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total</p>
            <h4 className="mb-0 sub-title">$1100</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6 className="green">
              <BsArrowDownRight /> 32%
            </h6>
            <p className="mb-0 desc">Compared To April 2022</p>
          </div>
        </div> */}
      </div>
      {/* <div className="mt-4">
        <h3 className="mb-5 title">Income Statics</h3>
        <div>
          <Column {...config} />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Orders</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
