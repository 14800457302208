import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { list } from "../features/reportUser/reportUserSlice";
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { IoSearchOutline } from "react-icons/io5";

const ReportUser = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1000,
    key: '',
    status: ''
  });

  useEffect(() => {
    dispatch(list(pagination));
  }, [dispatch, pagination]);


  const { isLoading, reportUsers, total } = useSelector((state) => state.reportUser);

  const handleTableChange = (pagination) => {
    dispatch(list(pagination));
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: total,
    }));
  }, [total]);


  const columns = [
    {
      title: "Reported by",
      dataIndex: "reportedBy",
      render: (text, record) => (
        <Link to={`/admin/profile/${record.report_by._id}`}>{record.report_by?.first_name} {record.report_by?.last_name}</Link>
      ),
    },
    {
      title: "Reported to",
      dataIndex: "reportedTo",
      render: (text, record) => (
        <Link to={`/admin/profile/${record.report_to._id}`}>{record.report_to?.first_name} {record.report_to?.last_name}</Link>
      )
    },
    {
      title: "Comment",
      dataIndex: "comment",
    },
    {
      title: 'Reported at',
      render: (record) => {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return `${formattedDate}, ${formattedTime}`;
      },
      key: 'reportedAt',
    },
  ];

  // Handle key down events on search
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPagination({
        ...pagination,
        current: 1,
        key: searchQuery,
      });
    }
  };

  return (
    <div>
      <div className="row-space-between">
        <h3 className="mb-4 title">Reported Profiles</h3>
        <div className="my-row">
          <div className="mr-5">
            {/* <IoSearchOutline className="fs-4" /> */}
            <Input placeholder="Search users"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              prefix={<IoSearchOutline className="fs-4" />}
            />
          </div>
        </div>
      </div>
      <p> {isLoading} </p>
      <div>
        <Table
          columns={columns}
          dataSource={reportUsers}
          rowKey={(record) => record.id}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default ReportUser;
