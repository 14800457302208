import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "./customerService";

export const getUsers = createAsyncThunk(
  "customer/get-customers",
  async (params, thunkAPI) => {
    try {
      return await customerService.getUsers(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk(
  "customer/get-customer",
  async (params, thunkAPI) => {
    try {
      return await customerService.getUser(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "customer/update-user-status",
  async (params, thunkAPI) => {
    try {
      return await customerService.updateUsersStatus(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "customer/get-dashboard-data",
  async (thunkAPI) => {
    try {
      return await customerService.getDashboardData();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  diamondPassUsers: [],
  customers: [],
  customer: {},
  dashboardData: {},
  total: 0,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  updatingStatus: false,
  loadingDashboard: false,
};
export const customerSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.customers = action.payload.users;
        state.total = action.payload.userCount;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateUserStatus.pending, (state, action) => {
        const { id, data } = action.meta.arg;
        state.customers = state.customers.map((customer) => 
        customer.id === id ? { ...customer, ...data } : customer
      );

        // state.customers = state.customers.map((customer) => customer.id === action.meta.arg.id ? { ...customer, status: action.meta.arg.data.status } : customer);
        state.updatingStatus = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.updatingStatus = false;
      })

      .addCase(getDashboardData.pending, (state, action) => {
        state.loadingDashboard = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.dashboardData = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.customer = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

  },
});
export default customerSlice.reducer;
