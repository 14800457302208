import axiosInstance from "../../utils/axiosInstance";

const list = async (params) => {
  const response = await axiosInstance.get(`report-user?limit=${params.pageSize}&skip=${(params.current - 1) * params.pageSize}&key=${params.key}`);
  return response.data.data;
};

const get = async (params) => {
  const response = await axiosInstance.get(`report-user/${params}`);
  return response.data.data;
};

const customerService = {
  list,
  get
};

export default customerService;
