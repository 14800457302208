import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import settingService from "./settingService";

export const getSetting = createAsyncThunk(
  "setting/get-setting",
  async (thunkAPI) => {
    try {
      return await settingService.getSetting();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSetting = createAsyncThunk(
  "setting/update-setting",
  async (data, thunkAPI) => {
    try {
      return await settingService.updateSetting(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  setting: {},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  updating: false,
  isUpdateSuccess: false
};
export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    initSetting: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.updating = false;
      state.isUpdateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.setting = action.payload;
      })
      .addCase(getSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateSetting.pending, (state, action) => {
        state.updating = true;
        state.isUpdateSuccess = false;
      })
      .addCase(updateSetting.rejected, (state, action) => {
        state.isError = true;
        state.message = Array.isArray(action.payload.response.data.message)
          ? action.payload.response.data.message[0]
          : action.payload.response.data.message;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.setting = action.payload;
        state.updating = false;
        state.isUpdateSuccess = true;
      })
  },
});
export default settingSlice.reducer;
export const { initSetting } = settingSlice.actions;