import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { list } from "../features/userPayment/userPaymentSlice";
import { Tag } from "antd";

const UserPayment = () => {

  // const [searchQuery, setSearchQuery] = useState('');

  // const handleInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    key: '',
    status: ''
  });

  // Fetch data on component mount and pagination change
  useEffect(() => {
    const { current, pageSize } = pagination;
    const skip = (current - 1) * pageSize;
    dispatch(list({ limit: pageSize, skip }));
  }, [dispatch, pagination.current, pagination.pageSize]);

  const { userPayments, total, isLoading } = useSelector((state) => state.userPayments);

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: total,
    }));
  }, [total]);

  const columns = [
    {
      title: 'Date',
      render: (record) => {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return `${formattedDate}, ${formattedTime}`;
      },
      key: 'createdAt',
    },
    {
      title: "User",
      key: "user_id",
      render: (record) => {
        return (
          <div>
            <p>{record.user_id.first_name} {record.user_id.last_name}</p>
          </div>
        );
      }
    },
    {
      title: "Type",
      dataIndex: "payment_type",
    },
    {
      title: "Product Id",
      key: "subscription_id",
      render: (record) => {
        return (
          <div>
            <p>{record.subscription_id.product_id}</p>
          </div>
        );
      }
    },
    {
      title: "Transaction_id",
      dataIndex: "transaction_id",
    },
    {
      title: "Currency",
      key: "subscription_id",
      render: (record) => {
        return (
          <div>
            <p>{record.subscription_id.currency}</p>
          </div>
        );
      }
    },
    {
      title: "Amount",
      dataIndex: "unit_amount",
    },
  ];

  // Handle key down events on search
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     setPagination({
  //       ...pagination,
  //       current: 1,
  //       key: searchQuery,
  //     });
  //   }
  // };

  return (
    <div>
      <div className="row-space-between">
        <h3 className="mb-4 title">User Payments</h3>
        <div className="my-row">
          <div className="mr-5">
            {/* <IoSearchOutline className="fs-4" /> */}
            {/* <Input placeholder="Search users"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              prefix={<IoSearchOutline className="fs-4" />}
            /> */}
          </div>
        </div>
      </div>
      <p> {isLoading} </p>
      <div>
        <Table
          columns={columns}
          dataSource={userPayments}
          rowKey={(record) => record._id}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default UserPayment;
