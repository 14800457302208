/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table, Switch, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions, updateSubscriptionStatus, deleteSubscription } from "../../features/subscription/subscriptionSlice";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { Select } from "antd";
import { IoSearchOutline } from "react-icons/io5";
import { Modal } from "antd";
const formatDate = (isoString) => {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const typeLabel = (type) => {
  const types = {
    'subscription': 'Subscription',
    'instant_pay': 'Instant Pay',
    'diamond_pass': 'Diamond Pass',
  };
  return types[type];
};

const periodLabel = (period) => {
  const periods = {
    'day': 'Daily',
    'week': 'Weekly',
    'month': 'Monthly',
    '3months': '3 Months',
    '6months': '6 Months',
    'year': 'Yearly',
  };
  return periods[period];
};

const Subscriptions = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    setPagination({
      ...pagination,
      current: 1,
      key: event.target.value,
    });
  };

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1000,
    key: "",
    status: "",
  });

  useEffect(() => {
    loadSubscriptions();
  }, [pagination]);

  async function loadSubscriptions() {
    dispatch(getSubscriptions(pagination));
  }

  const { isLoading, subscriptions, total } = useSelector(
    (state) => state.subscription
  );

  const handleTableChange = (pagination) => {
    dispatch(getSubscriptions(pagination));
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: total,
    }));
  }, [total]);

  const handleStatusChange = (checked, record) => {
    const newStatus = checked ? 1 : 0;
    dispatch(updateSubscriptionStatus({ id: record.id, data: { status: newStatus } }));
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this subscription?',
      content: 'This action cannot be undone.',
      onOk: () => {
        // Dispatch the delete action if confirmed
        dispatch(deleteSubscription({ id: id }));
      },
      onCancel() {
        // Optional: handle cancellation
      },
    });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <span>
          {typeLabel(type)}
        </span>
      ),
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (period) => (
        <span>
          {periodLabel(period)}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <span>
          {formatDate(createdAt)}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <span>
          <Switch
            checked={status === 1}
            onChange={(checked) => handleStatusChange(checked, record)}
          />
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (status, record) => (
        <span>
          <Link to={`/admin/subscription/${record.id}`}>Edit</Link>
          <span style={{ margin: "0 10px" }}>|</span>
          <a
            onClick={() => handleDelete(record.id)} // Call your delete function here
            style={{ color: "red", cursor: "pointer" }}
          >
            Delete
          </a>
        </span>
      ),
    },

  ];

  // Handle key down events on search
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setPagination({
        ...pagination,
        current: 1,
        key: searchQuery,
      });
    }
  };

  /** Handle dorp down for status is changed call search */
  const handleChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      status: value,
    });
  };

  return (
    <div>
      <h3 className="mb-4 title">Subscription Plans</h3>
      <div className="row-space-between">
        <Link to="/admin/subscription/create">
          <Button type="primary" htmlType="submit">
            Add New
          </Button>
        </Link>
        <div className="my-row">
          <div className="mr-5">
            <Input
              placeholder="Search subscriptions"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              prefix={<IoSearchOutline className="fs-4" />}
            />
          </div>

          <div>
            <Select
              defaultValue="All"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "", label: "All" },
                { value: "1", label: "Active" },
                { value: "0", label: "Inactive" },
              ]}
            />
          </div>
        </div>
      </div>
      <p> {isLoading} </p>
      <div>
        <Table
          columns={columns}
          dataSource={subscriptions}
          rowKey={(record) => record.id}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default Subscriptions;
