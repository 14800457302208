import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { list } from "../features/paymentLog/paymentLogSlice";

const PaymentLog = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1000,
    key: '',
    status: ''
  });

  useEffect(() => {
    dispatch(list(pagination));
  }, [dispatch, pagination]);


  const { logs, total, isLoading } = useSelector((state) => state.paymentLogs);

  const handleTableChange = (pagination) => {
    dispatch(list(pagination));
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: total,
    }));
  }, [total]);

  const columns = [
    {
      title: 'Date',
      render: (record) => {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return `${formattedDate}, ${formattedTime}`;
      },
      key: 'createdAt',
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: 'Data',
      key: 'data',
      render: (record) => {
        return (
          <div
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-word', // Wraps long words
            }}
          >
            {record.data}
          </div>
        );
      },
    }
  ];

  // Handle key down events on search
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPagination({
        ...pagination,
        current: 1,
        key: searchQuery,
      });
    }
  };

  return (
    <div>
      <div className="row-space-between">
        <h3 className="mb-4 title">Notification Logs</h3>
        <div className="my-row">
          <div className="mr-5">
            {/* <IoSearchOutline className="fs-4" /> */}
            {/* <Input placeholder="Search users"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              prefix={<IoSearchOutline className="fs-4" />}
            /> */}
          </div>
        </div>
      </div>
      <p> {isLoading} </p>
      <div>
        <Table
          columns={columns}
          dataSource={logs}
          rowKey={(record) => record._id}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default PaymentLog;
