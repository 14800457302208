import React, { useEffect, useState } from "react";
import { Table, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Image, Button } from "antd";
import { Tag } from "antd";
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { Select } from 'antd';
import { IoSearchOutline } from "react-icons/io5";
import { message as msg } from 'antd';
import { getNormalUser, updateNormalUserStatus } from '../features/cutomers/normalUserSlice';
import { getDiamongPassUser } from '../features/cutomers/diamondPassUserSlice';
const NormalUser = () => {
    const [messageApi, contextHolder] = msg.useMessage();
    const [searchQuery, setSearchQuery] = useState('');
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const dispatch = useDispatch();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 1000,
        key: '',
        status: '',
        sort: 'likes_desc',
        is_diamond_pass_user: false
    });

    useEffect(() => {
        dispatch(getNormalUser(pagination));
    }, [dispatch, pagination]);


    const { isLoading, mormalUsers, total } = useSelector((state) => state.mormalUsers);

    const handleTableChange = (pagination) => {
        dispatch(getNormalUser(pagination));
        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            total: total,
        }));
    }, [total]);

    const handleNormalChange = (checked, record) => {
        dispatch(updateNormalUserStatus({ id: record.id, data: { is_diamond_pass_user: true } }));
        dispatch(getDiamongPassUser(
            {
                current: 1,
                pageSize: 10,
                total: 1000,
                key: '',
                status: '',
                sort: 'likes_desc',
                is_diamond_pass_user: true
            }
        ));

        messageApi.open({
            type: 'success',
            content: `User added for diamond pass section`,
        });
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text, record) => (
                <Image
                    width={50}
                    style={{ borderRadius: "50%" }}
                    src={record.profile_pic_url}
                    alt={`${record.first_name} ${record.last_name}`}
                />
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (status, record) => (
                <Link to={`/admin/profile/${record.id}`}>{record.first_name} {record.last_name}</Link>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "gender",
            dataIndex: "gender",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        {
            title: "Age",
            dataIndex: "age",
        },
        {
            title: "Likes",
            dataIndex: "likes",
        },
        {
            title: "Dislikes",
            dataIndex: "dislikes",
        },      
        {
            title: "Interest",
            dataIndex: "interest",
            render: (_, { interest }) => (
                <>
                    {interest.map((tag) => {


                        let color = 'green';

                        if (tag === 'female') {
                            color = 'volcano';
                        }

                        if (tag === 'non-binary') {
                            color = 'geekblue';
                        }
                        return (
                            <Tag color={color} key={`${tag}-${Math.random()}`}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'is_diamond_pass_user',
            key: 'is_diamond_pass_user',
            render: (is_diamond_pass_user, record) => (
                <Button onClick={() => handleNormalChange(is_diamond_pass_user, record)}>
                    Add to DP
                </Button>
            ),
        },
    ];

    // Handle key down events on search
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setPagination({
                ...pagination,
                current: 1,
                key: searchQuery,
            });
        }
    };

    /** Handle dorp down for status is changed call search */
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setPagination({
            ...pagination,
            current: 1,
            status: value
        });
    };

     /** Handle dorp down for status is changed call search */
     const handleGenderChange = (value) => {
        console.log(`selected ${value}`);
        setPagination({
            ...pagination,
            current: 1,
            gender: value
        });
    };

    return (
        <div>
            {contextHolder}
            <div className="row-space-between">
                <h3 className="mb-4 title">Users</h3>
                <div className="my-row">
                    <div className="mr-5">
                        {/* <IoSearchOutline className="fs-4" /> */}
                        <Input placeholder="Search users"
                            value={searchQuery}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            prefix={<IoSearchOutline className="fs-4" />}
                        />
                    </div>
                    <div>
                        <Select
                            defaultValue="All Gender"
                            style={{ width: 120 }}
                            onChange={handleGenderChange}
                            options={[
                                { value: '', label: 'All Gender' },
                                { value: 'male', label: 'Male' },
                                { value: 'female', label: 'Female' },
                                { value: 'non-binary', label: 'Non Binary' },
                                { value: 'open', label: 'Open' },
                            ]}
                        />
                    </div>
                    <div>
                        <Select
                            defaultValue="all"
                            style={{ width: 120 }}
                            onChange={handleChange}
                            options={[
                                { value: '', label: 'all' },
                                { value: '1', label: 'Active' },
                                { value: '2', label: 'In-Active' },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <p> {isLoading} </p>
            <div>
                <Table
                    columns={columns}
                    dataSource={mormalUsers}
                    rowKey={(record) => record.id}
                    onChange={handleTableChange}
                    pagination={pagination}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default NormalUser;
