import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentLogs from './paymentLog';

export const list = createAsyncThunk(
  "logs/list",
  async (params, thunkAPI) => {
    try {      
      return await paymentLogs.list(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const initialState = {
  logs: [],
  total: 0,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  updatingStatus: false,
  loadingDashboard: false,
};
export const paymentLogSlice = createSlice({
  name: "paymentLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.logs = action.payload.data;
        state.total = action.payload.count;        
      })
      .addCase(list.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })     
  },
});
export default paymentLogSlice.reducer;
