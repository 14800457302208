import axiosInstance from "../../utils/axiosInstance";

const list = async (params) => {
  const response = await axiosInstance.get(`subscription/logs?limit=${params.pageSize}&skip=${(params.current - 1) * params.pageSize}&key=${params.key}`);
  return response.data.data;
};

const paymentLogs = {
  list,  
};

export default paymentLogs;
