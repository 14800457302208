import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { getPage } from "./../features/page/pageSlice";
import { useDispatch, useSelector } from "react-redux";

const Page = () => {
    const dispatch = useDispatch();
    const { type } = useParams();
    const { isError, page } = useSelector((state) => state.page);

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = () => {
        // TermsAndConditions = 'termsAndConditions',
        // Tips = 'tips',
        // Safety = 'safety',
        // PrivacyPolicy = 'privacyPolicy',
        // UserAgreement = 'userAgreement',

        let pageType;

        if (type === "terms-and-conditions") {
            pageType = "termsAndConditions";
        }

        if (type === "tips") {
            pageType = "tips";
        }

        if (type === "safety") {
            pageType = "safety";
        }

        if (type === "privacy-policy") {
            pageType = "privacyPolicy";
        }

        if (type === "user-agreement") {
            pageType = "userAgreement";
        }

        dispatch(getPage(pageType));
    };

    return (
        <div className="container">
            <div className="py-5" style={{ minHeight: "100vh" }}>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: page }} />
                </div>
            </div>
        </div>

    );
};

export default Page;
