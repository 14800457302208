import axiosInstance from "../../utils/axiosInstance";
import { buildQueryParams } from '../../services/utility';

const list = async (params) => {
  const queryString = buildQueryParams(params);
  const response = await axiosInstance.get(`subscription/user-payment?${queryString}`);
  return response.data.data;
};

const userPayment = {
  list,
};

export default userPayment;
