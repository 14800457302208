import React, { useEffect, useState } from "react";
import { Table, Switch } from "antd";
import { message as msg } from 'antd';

import  DiamondPassUser  from "./../components/DiamondPassUser";
import NormalUser from "../components/NormalUser";

const DiamondUser = () => {
  const [messageApi, contextHolder] = msg.useMessage();
  const [searchQuery, setSearchQuery] = useState('');
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <DiamondPassUser></DiamondPassUser>
      <NormalUser></NormalUser>      
    </div>
  );
};

export default DiamondUser;
