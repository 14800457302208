import axiosInstance from "../../utils/axiosInstance";
import { buildQueryParams } from './../../services/utility';

const sendNotification = async (data) => {
  const response = await axiosInstance.post(`push-notification`, data);
  return response.data.data;
};

const getUsers = async (params) => {
  const queryString = buildQueryParams(params);
  const response = await axiosInstance.get(`user?${queryString}`);
  return response.data.data;
};

const notificationService = {
  sendNotification,
  getUsers
};

export default notificationService;
