import React, { useEffect, useState } from "react";
import { Table, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, updateUserStatus } from "../features/cutomers/customerSlice";
import { Image } from "antd";
import { Tag } from "antd";
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { Select } from 'antd';
import { IoSearchOutline } from "react-icons/io5";
import { message as msg } from 'antd';

const Customers = () => {
  const [messageApi, contextHolder] = msg.useMessage();
  const [searchQuery, setSearchQuery] = useState('');
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1000,
    key: '',
    status: '',
  });

  useEffect(() => {
    dispatch(getUsers(pagination));
  }, [dispatch, pagination]);


  const { isLoading, customers, total } = useSelector((state) => state.customer);

  const handleTableChange = (pagination) => {
    dispatch(getUsers(pagination));
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: total,
    }));
  }, [total]);

  const handleStatusChange = (checked, record) => {
    const newStatus = checked ? 1 : 2;
    dispatch(updateUserStatus({ id: record.id, data: { status: newStatus } }));
    messageApi.open({
      type: 'success',
      content: "User status updated successfully",
    });
  };

  const handleNotificationChange = (checked, record) => {
    dispatch(updateUserStatus({ id: record.id, data: { enable_notification: checked } }));
    messageApi.open({
      type: 'success',
      content: `Notification is ${checked ? 'enabled' : 'disabled'} successfully`,
    });
  };

  const handleDiamondPassChange = (checked, record) => {
    dispatch(updateUserStatus({ id: record.id, data: { is_diamond_pass_user: checked } }));
    messageApi.open({
      type: 'success',
      content: `Use is  ${checked ? 'added' : 'removed' } for diamond pass section`,
    });
  };


  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => (
        <Image
          width={40}
          height={40}
          style={{ borderRadius: "50%" }}
          src={record.profile_pic_url}
          alt={`${record.first_name} ${record.last_name}`}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      // sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: 'Created at',
      render: (record) => {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });      
        return `${formattedDate}`;
      },
      key: 'reportedAt',
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Likes",
      dataIndex: "likes",
    },
    {
      title: "Dislikes",
      dataIndex: "dislikes",
    },
    {
      title: "gender",
      dataIndex: "gender",
    },
    {
      title: "Date Type",
      dataIndex: "date_type",   
      render: (_, { date_type }) => (
        <>
          {date_type.map((tag) => {
            
            let color = 'green';
            if (tag === 'yacht_ride') {
              color = 'volcano';
            }
            if (tag === 'clubbing') {
              color = 'geekblue';
            }
            return (
              <Tag color={color} key={`${tag}-${Math.random()}`}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),   
    },  
    {
      title: "Interest",
      dataIndex: "interest",
      render: (_, { interest }) => (
        <>
          {interest.map((tag) => {


            let color = 'green';

            if (tag === 'female') {
              color = 'volcano';
            }

            if (tag === 'non-binary') {
              color = 'geekblue';
            }
            return (
              <Tag color={color} key={`${tag}-${Math.random()}`}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Notification',
      dataIndex: 'enable_notification',
      key: 'enable_notification',
      render: (enable_notification, record) => (
        <Switch
          checked={enable_notification}
          onChange={(checked) => handleNotificationChange(checked, record)}
        />
      ),
    },
    // {
    //   title: 'Diamond Profile',
    //   dataIndex: 'is_diamond_pass_user',
    //   key: 'is_diamond_pass_user',
    //   render: (is_diamond_pass_user, record) => (
    //     <Switch
    //       checked={is_diamond_pass_user}
    //       onChange={(checked) => handleDiamondPassChange(checked, record)}
    //     />
    //   ),
    // },    
    {
      title: 'Block',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <span>
          <Switch
            checked={status === 1}
            onChange={(checked) => handleStatusChange(checked, record)}
          />
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (status, record) => (
        <Link to={`/admin/profile/${record.id}`}>view</Link>
      ),
    },
  ];

  // Handle key down events on search
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPagination({
        ...pagination,
        current: 1,
        key: searchQuery,
      });
    }
  };

  /** Handle dorp down for status is changed call search */
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setPagination({
      ...pagination,
      current: 1,
      status: value
    });
  };

  return (
    <div>
      {contextHolder}
      <div className="row-space-between">
        <h3 className="mb-4 title">Users</h3>
        <div className="my-row">
          <div className="mr-5">
            {/* <IoSearchOutline className="fs-4" /> */}
            <Input placeholder="Search users"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              prefix={<IoSearchOutline className="fs-4" />}
            />
          </div>

          <div>
            <Select
              defaultValue="all"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: '', label: 'all' },
                { value: '1', label: 'Active' },
                { value: '2', label: 'In-Active' },
              ]}
            />
          </div>
        </div>
      </div>
      <p> {isLoading} </p>
      <div>
        <Table
          columns={columns}
          dataSource={customers}
          rowKey={(record) => record.id}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default Customers;
