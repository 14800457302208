import React, { useEffect } from "react";
import { Card } from 'antd';
import { getUser } from "../features/cutomers/customerSlice";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
const { Meta } = Card;
const Profile = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    useEffect(() => {
        dispatch(getUser(id));
    }, []);

    const { isLoading, customer } = useSelector((state) => state.customer);

    return (
        <Card
            hoverable
            style={{ width: 240 }}
            cover={
                <img alt="example" src={customer.profile_pic_url} />
            }
        >
            <Meta
                title={`${customer.first_name} ${customer.last_name}`}
                description={customer.bio}
            />
            <p><b>Age</b> :  {customer.age}</p>
            {/* <p><b>Likes</b> :  {customer.likeCount}</p>
            <p><b>dislikes</b> :  {customer.disLikeCount}</p> */}
            <p><b>Phone</b> :  {customer.phone}</p>
            <p><b>Email</b> :  {customer.email}</p>

        </Card>
    );
};

export default Profile;
