import axiosInstance from "./../../utils/axiosInstance";
import { buildQueryParams } from './../../services/utility';

const getUsers = async (params) => {
  const queryString = buildQueryParams(params);
  const response = await axiosInstance.get(`user?${queryString}`);
  return response.data.data;
};

const getUser = async (params) => {
  const response = await axiosInstance.get(`user/${params}`);
  return response.data.data;
};

const updateUsersStatus = async (params) => {
  const response = await axiosInstance.put(`user/${params.id}`, params.data);
  return response.data.data;
};

const getDashboardData = async () => {
  const response = await axiosInstance.get(`user/dashboard`);
  return response.data.data;
};

const customerService = {
  getUsers,
  updateUsersStatus,
  getDashboardData,
  getUser
};

export default customerService;
