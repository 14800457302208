import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userPayment  from "./userPayment";

export const list = createAsyncThunk(
  "user-payment/list",
  async (params, thunkAPI) => {
    try {
      return await userPayment.list(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  userPayment: [],
  total: 0,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  loadingDashboard: false,
};
export const userPaymentSlice = createSlice({
  name: "userPayment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.userPayments = action.payload.data;
        state.total = action.payload.count;
      })
      .addCase(list.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
  },
});
export default userPaymentSlice.reducer;
