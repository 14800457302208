import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/auth/authSlice";
import { message as msg } from 'antd';

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});
const Login = () => {
  const [messageApi, contextHolder] = msg.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });
  const { user, isError, isLogin, isLoading, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError && message) {
      messageApi.open({
        type: 'error',
        content: message,
      });
    }
    if (isLogin) {
      navigate("admin");
    } else {
      navigate("");
    }
  }, [user, isError, isLogin, isLoading, message, messageApi, navigate]);


  return (
    <>
      {contextHolder}
      <div className="py-5 p-4" style={{ background: "white", minHeight: "100vh" }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="my-5 mx-auto p-4 bg-white rounded-3" style={{ maxWidth: "400px" }}>
          <h3 className="text-center title">Login</h3>
          <p className="text-center">Login to your account to continue.</p>
          <div className="error text-center">
            {message.message === "Rejected" ? "You are not an Admin" : ""}
          </div>
          <form action="" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <CustomInput
                type="text"
                label="Email Address"
                id="email"
                name="email"
                onChng={formik.handleChange("email")}
                onBlr={formik.handleBlur("email")}
                val={formik.values.email}
              />
              <div className="error mt-2">
                {formik.touched.email && formik.errors.email}
              </div>
            </div>
            <div className="mb-3">
              <CustomInput
                type="password"
                label="Password"
                id="pass"
                name="password"
                onChng={formik.handleChange("password")}
                onBlr={formik.handleBlur("password")}
                val={formik.values.password}
              />
              <div className="error mt-2">
                {formik.touched.password && formik.errors.password}
              </div>
              <div className="mb-3 text-end">
                <Link to="forgot-password" className="">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <button disabled={isLoading}
              className="border-0 px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5"
              style={{ background: "#ffd333" }}
              type="submit"
            >
              {isLoading ? 'Login ...' : 'Login'}
            </button>
            <div style={{
              marginTop: '5rem',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <a href="/page/terms-and-conditions">Terms and  Conditions</a>
              <a href="/page/tips">Tips</a>
              <a href="/page/safety">Safety</a>
              <a href="/page/privacy-policy">Privacy Policy</a>
              <a href="/page/user-agreement">User Agreement</a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;


//  TermsAndConditions = 'termsAndConditions',
//       Tips = 'tips',
//       Safety = 'safety',
//       PrivacyPolicy = 'privacyPolicy',
//       UserAgreement = 'userAgreement',