import axiosInstance from "./../../utils/axiosInstance";

const login = async (user) => {
  const response = await axiosInstance.post('auth/login', user);
  if (response.data && response.data.data && response.data.data.access_token) {

    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem("token", response.data.data.access_token);
    } else {
      console.warn("localStorage is not available.");
    }
  }
  if (response.data && response.data.data && response.data.data.user) {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
    } else {
      console.warn("localStorage is not available.");
    }
  }
  return response.data;
};

const logout = async () => {
  // const response = await axiosInstance.get('auth/logout');

  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return;
  } else {
    console.warn("localStorage is not available.");
  }
  // return response.data;
};


const authService = {
  logout,
  login,
};

export default authService;
