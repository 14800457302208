import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reportUserService from "./reportUser";

export const list = createAsyncThunk(
  "customer/list",
  async (params, thunkAPI) => {
    try {      
      return await reportUserService.list(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const get = createAsyncThunk(
  "customer/get",
  async (params, thunkAPI) => {
    try {
      return await reportUserService.get(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const initialState = {
  reportUsers: [],
  reportUser: {},
  total: 0,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  updatingStatus: false,
  loadingDashboard: false,
};
export const reportUserSlice = createSlice({
  name: "reportUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.reportUsers = action.payload.reports;
        state.total = action.payload.count;        
      })
      .addCase(list.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })     
      .addCase(get.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.reportUser = action.payload;
      })
      .addCase(get.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

  },
});
export default reportUserSlice.reducer;
