import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userSubscription from './userSubscription';

export const list = createAsyncThunk(
  "user-subscription/list",
  async (params, thunkAPI) => {
    try {
      return await userSubscription.list(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  userSubscriptions: [],
  total: 0,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  updatingStatus: false,
  loadingDashboard: false,
};
export const userSubscriptionSlice = createSlice({
  name: "userSubscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.userSubscriptions = action.payload.data;
        state.total = action.payload.count;
      })
      .addCase(list.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
  },
});
export default userSubscriptionSlice.reducer;
