import axiosInstance from "../../utils/axiosInstance";

const getSubscriptions = async (params) => {
  const response = await axiosInstance.get(`subscription?limit=${params.pageSize}&skip=${(params.current - 1) * params.pageSize}&key=${params.key}&status=${params.status}`);
  return response.data.data;
};

const getSubscription = async (id) => {
  const response = await axiosInstance.get(`subscription/${id}`);
  return response.data.data;
};

const createSubscription = async (data) => {
  const response = await axiosInstance.post(`subscription`, data);
  return response.data.data;
};

const updateSubscription = async (params) => {
  const response = await axiosInstance.put(`subscription/${params.id}`, params.data);
  return response.data.data;
};

const deleteSubscription = async (params) => {
  const response = await axiosInstance.delete(`subscription/${params.id}`);
  return response.data.data;
};

const updateSubscriptionStatus = async (params) => {
  const response = await axiosInstance.put(`subscription/${params.id}`, params.data);
  return response.data.data;
};

const subscriptionService = {
  getSubscriptions,
  updateSubscription,
  createSubscription,
  updateSubscriptionStatus,
  getSubscription,
  deleteSubscription,
};

export default subscriptionService;
