import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "./customerService";

export const getNormalUser = createAsyncThunk(
    "customer/get-normal-user",
    async (params, thunkAPI) => {
        try {
            return await customerService.getUsers(params);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updateNormalUserStatus = createAsyncThunk(
    "customer/update-normal-user-status",
    async (params, thunkAPI) => {
        try {
            return await customerService.updateUsersStatus(params);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const initialState = {
    mormalUsers: [],
    total: 0,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
    updatingStatus: false,
};

export const customerSlice = createSlice({
    name: "diamondPassusers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNormalUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getNormalUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.mormalUsers = action.payload.users;
                state.total = action.payload.userCount;
            })
            .addCase(getNormalUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateNormalUserStatus.pending, (state, action) => {
                const { id, data } = action.meta.arg;                
                if (data.is_diamond_pass_user) {
                    state.mormalUsers = state.mormalUsers.filter((user) => user.id !== id);
                }
                state.updatingStatus = true;
            })
            .addCase(updateNormalUserStatus.fulfilled, (state, action) => {
                state.updatingStatus = false;
            })
    },
    
});
export default customerSlice.reducer;
