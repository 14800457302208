import axiosInstance from "../../utils/axiosInstance";

const getSetting = async () => {
  const response = await axiosInstance.get(`setting`);
  return response.data.data;
};

const updateSetting = async (data) => {
  const response = await axiosInstance.put(`setting`, data);
  return response.data.data;
};

const settingService = {
  getSetting,
  updateSetting,
};

export default settingService;
