import axiosInstance from "../../utils/axiosInstance";

const get = async (type) => {
    const response = await axiosInstance.get(`setting/page/?type=${type}`);
    return response.data.data;
};

const pageService = {
    get
};

export default pageService;
